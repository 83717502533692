// import { DarkModeToggle } from "./DarkModeToggle";
import "./css/Header.css";
import { Link } from "react-router-dom";

const Header = () => {
	return (
		<header>
			<div className="header">
				<div className="header__links-container">
					<Link to="/" className="header__logo">
						Holmbakken
					</Link>
					<div className="header__left-side">
						<a href="/#projects">projects</a>
						{/* <DarkModeToggle /> */}
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
