import { Link } from "react-router-dom";

const NotFound = () => {
	return ( 
		<div className="not-found">
			<h2>This page doesn't exist</h2>
			<Link to="/">Back to the hompepage</Link>
		</div>
	 );
}
 
export default NotFound;