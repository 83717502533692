import { Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import './App.css';
import './variables.css';
import Home from './components/Home';
import Header from './components/Header';
import NotFound from './components/NotFound';
import ProjectPost from './pages/ProjectPost';


ReactGA.initialize('G-BBJC6PPP45');

function App() {
	return (
		<div className="App">
			<Header />
			<div className="content">
				<Routes>
					<Route path="/" element={<Home title="Sverre Holmbakken" />}></Route>
					<Route path="/projects/:slug" element={<ProjectPost />}></Route>
					<Route path="*" element={<NotFound />}></Route>
				</Routes>
			</div>
		</div>
	);
}

export default App;
