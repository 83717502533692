import './css/MainPageLinks.css'

function MainPageLinks() {
	return (
		<div className="main__links">
			<a href="https://github.com/SverreHolmbakken" className="main__link-href">
				<img src="assets/icons/Github - Negative.svg" alt="" className="main__link-image" />
			</a>

			<a href="https://www.linkedin.com/in/sverre-holmbakken" className="main__link-href">
				<img src="assets/icons/LinkedIn - Negative.svg" alt="" className="main__link-image" />
			</a>
			
			<a href="mailto:sverre@holmbakken.no" className="main__link-href">
				<img src="assets/icons/email.svg" alt="" className="main__link-image" />
			</a>
		</div>
	);
}

export default MainPageLinks;