import { createClient } from "@sanity/client";
import { useEffect, useState } from "react";
import MainPageLinks from "./MainPageLinks";
import ProjectList from "./ProjectList";

export const sanityClient = createClient({
	projectId: "9h99hgik",
	dataset: "production",
	useCdn: true,
	apiVersion: "2021-03-25",
});

const Home = (props) => {
	const title = props.title;

	const [projects, setProjects] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "project"] {
				name,
				"slug": slug.current,
				description,
				link,
				"image": image.asset->url,
				tags,
				"id": _id,
				_createdAt
			}`
			)
			.then((data) => {
				setProjects(data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err.message);
			});
	}, []);

	return (
		<div className="home">
			<div className="main-block">
				<h1 className="main__title">{title}</h1>
				<MainPageLinks />
			</div>

			{loading && <div>Loading...</div>}
			{projects && <ProjectList projects={projects} />}
		</div>
	);
};

export default Home;
