import "./css/ProjectList.css";
import { Link } from "react-router-dom";

const ProjectList = ({ projects }) => {
	const sortedProjects = projects.sort((a, b) => {
		if (a._createdAt < b._createdAt) {
			return 1;
		}
		if (a._createdAt > b._createdAt) {
			return -1;
		}
		return 0;
	});

	console.log(sortedProjects.map((project) => project._createdAt));

	return (
		<div className="project-list" id="projects">
			<h1>Projects</h1>
			<div className="project-list__grid">
				{projects &&
					sortedProjects.map((project) => (
						<Link
							to={"/projects/" + project.slug}
							relative="path"
							className="project__preview"
							key={project.id}
						>
							<img src={project.image} alt="" />
							<div className="project__preview-text">
								<h2 className="project__name">{project.name}</h2>
								<p className="project__description">
									{project.description}
								</p>
								<div className="project__bottom-row">
									{project.tags &&
										project.tags.map((tag) => (
											<p className="project__tag">{tag}</p>
										))}
								</div>
							</div>
						</Link>
					))}
			</div>
		</div>
	);
};

export default ProjectList;
