import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { sanityClient } from "../components/Home";
import { Link } from "react-router-dom";
import "../components/css/ProjectPost.css"
import { LoadingSpinner } from "../components/LoadingSpinner";

const ProjectPost = () => {
	const { slug }	= useParams();
	const [postData, setPostData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		sanityClient.fetch(
			`*[slug.current == "${slug}"]{
				name,
				slug,
				description,
				link,
				repositoryLink,
				"image": image.asset->url,
				tags
			}`,
			{ slug }
		)
		.then(data => {
			setPostData(data[0])
			setLoading(false)
		})
	}, [slug]);


	
	return (
		<div className="post-page">
			{ loading && <LoadingSpinner /> }

			{ postData && (
				<article className="post-details">
					<img className="post__image" src={postData.image} alt="" />
					<div className="post__content">
						<div className="post__top-row">
							<h1 className="post__title">{postData.name}</h1>
							<div className="post__links">
							<Link 
								className="primary-button" 
								to={postData.link}
								target="_blank">
									Visit live demo
							</Link>
							<Link
								className="post__repo-link"
								to={postData.repositoryLink}
								target="_blank">
									<img src="/assets/icons/Github - Negative.svg" alt="" className="post__repo-icon" />
							</Link>
						</div>
						</div>
						<p className="post__description">{postData.description}</p>
						<h2>Made with these technologies:</h2>
						<div className="post__tags">
								{postData.tags.map((tag, index) => (
									<span className="project__tag" key={index}>{tag}</span>
								))}
						</div>
					</div>
				</article>
			)}
		</div>
	);
}

export default ProjectPost;